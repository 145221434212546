import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileGroupUploaderComponent } from './file-group-uploader.component';
import { FileUploadFieldComponent } from './file-upload-field.component';
import { FileUploadService } from './file-upload.service';

@NgModule({
  imports: [CommonModule, MatInputModule, MatIconModule, MatProgressSpinnerModule],
  declarations: [FileUploadFieldComponent, FileGroupUploaderComponent],
  exports: [FileUploadFieldComponent, FileGroupUploaderComponent],
  providers: [FileUploadService],
})
export class VaFileUploaderModule {}
