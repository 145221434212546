import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CountryCode, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import { GalaxyCoreInputDirective } from '../core/core-input.directive';
import { CountryCodeList, CountryDetailsInterface } from './country-code-list';

const DEFAULT_COUNTRY_CODE = 'US';
const INVALID_PHONE_NUMBER_ERROR_KEY = 'GALAXY.INPUT.PHONE.VALIDATION_ERROR';

/** @deprecated - a new phone input is in development */
@Component({
  selector: 'glxy-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss', './../core/core.material-override.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true,
    },
  ],
})
export class PhoneInputComponent extends GalaxyCoreInputDirective<number> implements OnInit {
  @HostBinding('class') class = 'glxy-phone-number-input';
  @Input({ transform: booleanAttribute }) useGalaxyFormField = false;

  @Input() set phoneNumber(val: string) {
    if (!!val && val.startsWith('+')) {
      const ccc = CountryCodeList.find((cc) => {
        if (val.substring(1, cc.countryCallingCode.toString().length + 1) === cc.countryCallingCode.toString()) {
          return true;
        }
        return false;
      });
      if (ccc) {
        this.countryDetails$$.next(ccc);
      }
      const ph = this.parsePhoneNumber(val.substring(1));
      if (ph) {
        this.formControl.setValue(ph.formatInternational());
      }
    } else {
      this.formControl.setValue(val.toString());
    }
  }

  get countryCode(): string {
    return this.countryDetails$$.getValue().countryCode;
  }

  rawInputControl: UntypedFormControl = new UntypedFormControl('', { updateOn: 'blur' });

  nationalPhoneNumber$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  countryDetails$$: BehaviorSubject<CountryDetailsInterface> = new BehaviorSubject<CountryDetailsInterface>(
    this.getCountryDetails(DEFAULT_COUNTRY_CODE),
  );

  countryCodeList = CountryCodeList;
  focused = false;
  label = 'GALAXY.INPUT.PHONE.LABEL';

  private phoneNumberValidationFunc = (control: AbstractControl): ValidationErrors | null => {
    if (control.value === null || control.value === undefined) {
      return null;
    }
    const phoneNumber = this.parsePhoneNumber(control.value);
    if (phoneNumber) {
      if (phoneNumber.country) {
        this.countryDetails$$.next(this.getCountryDetails(phoneNumber.country));
      }
      this.nationalPhoneNumber$$.next(`${phoneNumber.formatInternational()}`);
      this.formControl.setValue(phoneNumber.number.toString());
    } else {
      this.nationalPhoneNumber$$.next(control.value);
      this.formControl.setValue(control.value);
    }

    if ((!!phoneNumber && phoneNumber.isValid()) || control.value === '') {
      return null;
    }

    this.collectErrorsAndUpdateRawInputFormControl([]);
    return { message: INVALID_PHONE_NUMBER_ERROR_KEY };
  };

  ngOnInit(): void {
    this.setupControl();
  }

  getCountryDetails(countryCode: string): CountryDetailsInterface {
    return CountryCodeList.find((c) => c.countryCode === countryCode.toUpperCase()) ?? CountryCodeList[0];
  }

  setupControl(): void {
    if (!this.validators) {
      this.validators = [];
    }
    // If the config is passed in, parse it first
    if (this.config) {
      this.id = this.config.id || '';
      this.label = this.config.label || '';
      this.placeholder = this.config.placeholder || '';
      this.formControl = this.config.formControl || new UntypedFormControl();
      this.required = this.config.required || false;
      this.validators = this.config.validators || [];
      this.disabled = this.config.disabled || false;
    }

    if (!this.formControl) {
      this.formControl = new UntypedFormControl();
    }

    const rawInputValidators = this.setupValidators();

    this.rawInputControl.setValidators(rawInputValidators);

    const validators = this.validators?.map((validator) => {
      return (control: AbstractControl): ValidationErrors | null => {
        if (!validator.validatorFn(control)) {
          return null;
        }
        return { message: validator.errorMessage };
      };
    });

    if (validators?.length) {
      this.formControl.setValidators(validators);
    }
    if (this.asyncValidators?.length) {
      this.asyncValidatorErrors$ = this.formControl.valueChanges.pipe(
        debounceTime(GalaxyCoreInputDirective.ASYNC_DEBOUNCE_DELAY),
        distinctUntilChanged(),
        mergeMap(() => this.runAsyncValidators()),
        map((result) => this.handleAsyncValidatorResultsForPhoneInput(result)),
        tap((result) => this.asyncValidatorErrors$$.next(result)),
      );
      this.validatorError$ = combineLatest([
        this.formControl.statusChanges,
        this.rawInputControl.statusChanges,
        this.asyncValidatorErrors$,
      ]).pipe(
        map(([, , asyncErrors]) => {
          if (asyncErrors?.length) {
            return asyncErrors[0];
          }
          return this.rawInputControl.errors ? this.rawInputControl.errors.message : '';
        }),
      );
    } else {
      this.validatorError$ = combineLatest([this.formControl.statusChanges, this.rawInputControl.statusChanges]).pipe(
        map(() => {
          return this.rawInputControl.errors ? this.rawInputControl.errors.message : '';
        }),
      );
    }

    if (typeof this.formControl.value === 'string' && !!this.formControl.value) {
      const phoneNumber = this.parsePhoneNumber(this.formControl.value);
      if (!!phoneNumber && phoneNumber.isValid()) {
        this.rawInputControl.setValue(phoneNumber.number);
      } else {
        this.rawInputControl.setValue(this.formControl.value);
      }
    }
  }

  setupValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [this.phoneNumberValidationFunc];

    // Drop in required validation
    if (this.required) {
      validators.push((control: AbstractControl): ValidationErrors | null => {
        if (!Validators.required(control)) {
          return null;
        }
        const error: ValidationErrors[] = [{ message: 'GALAXY.INPUT.CORE.VALIDATION_ERROR_REQ' }];
        this.collectErrorsAndUpdateRawInputFormControl(error);
        return error[0];
      });
    }
    return validators;
  }

  parsePhoneNumber(rawNumber: string): PhoneNumber | null {
    let phoneNumber = parsePhoneNumberFromString(
      `${rawNumber}`,
      this.countryDetails$$.getValue().countryCode as CountryCode,
    );
    if (!!phoneNumber && phoneNumber.isValid()) {
      return phoneNumber;
    }

    phoneNumber = parsePhoneNumberFromString(`+${rawNumber}`);
    if (!!phoneNumber && phoneNumber.isValid()) {
      return phoneNumber;
    }

    const countryDetails = this.countryDetails$$.getValue();
    const number = `+${countryDetails.countryCallingCode}${rawNumber}`;
    return parsePhoneNumberFromString(number, countryDetails.countryCode as CountryCode) ?? null;
  }

  handleAsyncValidatorResultsForPhoneInput(
    results: {
      validatorResult: ValidationErrors;
      errorMessage: string;
    }[],
  ): string[] {
    const filteredResults = results.filter((r) => r.validatorResult);
    const asyncHasErrors = results.some((r) => r.validatorResult);
    const errorMessages = results.map((r) => r.errorMessage);
    this.collectErrorsAndUpdateRawInputFormControl(filteredResults);
    return asyncHasErrors ? errorMessages : [];
  }

  collectErrorsAndUpdateRawInputFormControl(errors: ValidationErrors[]): void {
    const allErrorsMap: ValidationErrors = {};
    if (this.formControl.errors) errors.push(this.formControl.errors);
    if (this.rawInputControl.errors) errors.push(this.rawInputControl.errors);
    errors.forEach((errorSet) => {
      if (errorSet) {
        Object.keys(errorSet).forEach((key: string) => {
          return (allErrorsMap[key] = errorSet[key]);
        });
      }
    });
    this.formControl.setErrors(allErrorsMap);
    this.rawInputControl.setErrors(allErrorsMap);
  }

  /**
   * Toggles the state of whether to show the input value or the parsed input text
   * @param state - State of focus
   */
  toggleFocus(state: boolean): void {
    this.focused = state;
  }

  updateCountryDetails(countryCode: string): void {
    this.countryDetails$$.next(
      CountryCodeList.find((c) => c.countryCode === countryCode.toUpperCase()) ?? CountryCodeList[0],
    );
    this.rawInputControl.updateValueAndValidity();
    this.toggleFocus(false);
  }

  blurInput(): void {
    if (!this.asyncValidatorErrors$$.getValue().length) {
      this.rawInputControl.updateValueAndValidity();
    }
    this.toggleFocus(false);
  }

  setDisabledState(isDisabled: boolean): void {
    // Do nothing when no change
    if (isDisabled === this.rawInputControl?.disabled) {
      return;
    }

    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.rawInputControl.disable();
    } else {
      this.rawInputControl.enable();
    }
  }
}
