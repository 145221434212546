import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  forwardRef,
  HostBinding,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, NG_VALUE_ACCESSOR, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { GalaxyCoreInputDirective } from '../core/core-input.directive';
import { GalaxyInputValidator, GalaxyPasswordInputInterface } from './../input.interface';

const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
const mediumRegex = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
);

/** @deprecated - can be updated to use Galaxy Form Field */
@Component({
  selector: 'glxy-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./../core/core.material-override.scss', './password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true,
    },
  ],
})
export class PasswordInputComponent extends GalaxyCoreInputDirective<string> implements OnInit {
  @HostBinding('class') class = 'glxy-password-input';

  /** Show password strength indicator. */
  @Input({ transform: booleanAttribute }) showPasswordStrength = false;
  /** Show confirm password input */
  @Input({ transform: booleanAttribute }) confirmPassword = false;
  @Input() config?: GalaxyPasswordInputInterface;

  confirmPasswordFormControl = new UntypedFormControl('');
  confirmPasswordValidator: GalaxyInputValidator[] = [];

  currentType = 'password';
  currentIcon = 'visibility';

  passwordStrength$?: Observable<string>;
  strengthLabel$?: Observable<string>;

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.setupControl();
  }

  setupControl(): void {
    if (this.config) {
      this.showPasswordStrength = this.config.showPasswordStrength
        ? this.config.showPasswordStrength
        : this.showPasswordStrength;
      this.confirmPassword = this.config.confirmPassword ? this.config.confirmPassword : this.confirmPassword;
    }

    // Make sure the form control is defined
    if (!this.formControl) {
      this.formControl = new UntypedFormControl('');
    }

    // Check if we need to display the confirm password input
    if (this.confirmPassword) {
      if (!this.validators) {
        this.validators = [];
      }
      this.confirmPasswordValidator = [...this.validators];
      this.validators.push({
        validatorFn: validatePasswordInputsMatch(this.confirmPasswordFormControl),
        errorMessage: 'GALAXY.INPUT.PASSWORD.VALIDATION_ERROR_NO_MATCH',
      });

      this.confirmPasswordValidator.push({
        validatorFn: validatePasswordInputsMatch(this.formControl),
        errorMessage: 'GALAXY.INPUT.PASSWORD.VALIDATION_ERROR_NO_MATCH',
      });
      this.confirmPasswordFormControl.setValidators(
        this.confirmPasswordValidator.map((validator) => {
          return validator.validatorFn;
        }),
      );
      this.addListenersForPasswordMatching();
    }

    this.passwordStrength$ = this.formControl.valueChanges.pipe(map(this.calculatePasswordStrength), startWith('none'));

    this.strengthLabel$ = this.passwordStrength$.pipe(map(this.getStrengthKey));

    super.setupControl();
  }

  /**
   * Add listeners for value changes. If either value changes, we should compare the two values to see if they match.
   */
  private addListenersForPasswordMatching(): void {
    combineLatest([this.formControl.valueChanges, this.confirmPasswordFormControl.valueChanges])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(
          ([fcPrevious, cpPrevious], [fcCurrent, cpCurrent]) => fcPrevious === fcCurrent && cpPrevious === cpCurrent,
        ),
        tap(() => {
          this.formControl.updateValueAndValidity();
          this.confirmPasswordFormControl.updateValueAndValidity();
        }),
      )
      .subscribe();
  }

  /**
   * Toggles the visibility of the inputs
   */
  togglePasswordVisibility(): void {
    if (this.currentType === 'password') {
      this.currentType = 'text';
      this.currentIcon = 'visibility_off';
      return;
    }
    this.currentType = 'password';
    this.currentIcon = 'visibility';
  }

  /**
   * Calculates the password strength base on the following:
   *    Strong:
   *      - Must contain 1 lowercase character
   *      - Must contain 1 uppercase letter
   *      - Must conatin 1 numeric character
   *      - Must contain 1 special character
   *      - Must be 8 characters or longer
   *    Medium:
   *      - lowercase characters and uppercase characters
   *      - lowercase characters and numeric characters
   *      - uppercase characters and numberic characters
   *      - Must be 6 characters or longer
   * Will return strong, medium or weak
   * @param password - Password to check
   */
  private calculatePasswordStrength(password: string): string {
    if (strongRegex.test(password)) {
      return 'strong';
    } else if (mediumRegex.test(password)) {
      return 'medium';
    }
    return 'weak';
  }

  private getStrengthKey(strength: string): string {
    let key;

    switch (strength) {
      case 'strong':
        key = 'GALAXY.INPUT.PASSWORD.STRENGTH_STRONG';
        break;
      case 'medium':
        key = 'GALAXY.INPUT.PASSWORD.STRENGTH_MEDIUM';
        break;
      case 'weak':
        key = 'GALAXY.INPUT.PASSWORD.STRENGTH_WEAK';
        break;
      default:
        key = 'GALAXY.INPUT.PASSWORD.STRENGTH_NONE';
    }

    return key;
  }
}

/**
 * Compares the passed in control to the control being checked.
 * @param compareControl - Form Control to compare against
 */
function validatePasswordInputsMatch(compareControl: UntypedFormControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((!control.value && !compareControl.value) || control.value === compareControl.value) {
      return null;
    }
    return { passwordmatch: true };
  };
}
