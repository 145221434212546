<mat-form-field
  class="galaxy-form-field galaxy-select-container"
  [ngClass]="{
    'no-hint-message': !hint && (!formControl.invalid || formControl.pristine),
  }"
  appearance="outline"
>
  @if (label) {
    <mat-label>{{ label | translate }}</mat-label>
  }
  <div class="select-size-container"></div>
  <mat-select
    [aria-label]="label ? (label | translate) : ''"
    matNativeControl
    [id]="id"
    [formControl]="formControl"
    [required]="!!required"
    (selectionChange)="writeValue($event.value)"
    [panelClass]="'galaxy-mat-select-panel is-dense'"
    [value]="formControl.value"
    (blur)="onBlur()"
  >
    <mat-select-trigger>
      {{ selectText | translate }}
    </mat-select-trigger>
    @for (option of options; track option) {
      @if (option | getOption; as optionObj) {
        <mat-option
          [value]="optionObj.value"
          [disabled]="option.disabled"
          (onSelectionChange)="selected($event, option.label)"
        >
          <span class="glxy-option-text label">
            {{ option.label | translate }}
          </span>
          @if (!!optionObj.description) {
            <br />
            <span class="glxy-option-text description">
              {{ optionObj.description | translate }}
            </span>
          }
        </mat-option>
      }
      @if (option | getOptionGroup; as optionGroup) {
        <mat-optgroup [label]="option.label" [disabled]="option.disabled">
          @for (subOption of optionGroup.options; track subOption) {
            <mat-option
              [value]="subOption.value"
              [disabled]="subOption.disabled"
              (onSelectionChange)="selected($event, subOption.label)"
            >
              <span class="glxy-option-text label">
                {{ subOption.label | translate }}
              </span>
              <br />
              @if (!!subOption.description) {
                <span class="glxy-option-text description">
                  {{ subOption.description | translate }}
                </span>
              }
            </mat-option>
          }
        </mat-optgroup>
      }
    }
  </mat-select>
  @if (hint) {
    <mat-hint class="galaxy-hint">{{ hint | translate }}</mat-hint>
  }

  @if (validatorError$ | async; as validatorError) {
    <mat-error>
      @if (validatorError !== '') {
        {{ validatorError | translate }}
      }
    </mat-error>
  }
</mat-form-field>
