import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { HoursOfOperationComponent } from './controls/hours-of-operation/hours-of-operation.component';
import { InputComponent } from './controls/input/input.component';
import { InputRepeatedComponent } from './controls/input-repeated/input-repeated.component';
import { GlxyInputRepeatedComponent } from './controls/input-repeated-glxy/input-repeated-glxy.component';
import { InputTextareaComponent } from './controls/input-textarea/input-textarea.component';
import { InputTagsComponent } from './controls/input-tags/input-tags.component';
import { GlxyInputTagsComponent } from './controls/input-tags-glxy/input-tags-glxy.component';
import { FileUploadService } from './controls/va-file-upload/file-upload.service';
import { GeoComponent } from './controls/geo/geo.component';
import { VaImageUploaderModule } from './controls/image-upload/image-upload.module';
import { CountryStateComponent } from './controls/country-state/country-state.component';
import { EditHoursOfOperationDialogComponent } from './controls/hours-of-operation/edit-dialog/edit-dialog.component';
import { PhoneNumberMultiComponent } from './controls/phone-number-multi/phone-number-multi.component';
import { DayOfWeekDisplayComponent } from './controls/hours-of-operation/inner-components/day-of-week-display.component';
import { DayOfWeekEditComponent } from './controls/hours-of-operation/inner-components/day-of-week-edit.component';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HoursOfOperationTimeSpanEditComponent } from './controls/hours-of-operation/inner-components/time-span-edit.component';
import { TimePickerComponent } from './controls/time-picker/time-picker.component';
import { VaFileUploaderModule } from './controls/va-file-upload/va-file-upload.module';
import { TimeRangeSelectorComponent } from './controls/time-range-selector/time-range-selector.component';
import { ErrorControlDirective } from './controls/error-control.component';
import { ErrorRepeatedControlDirective } from './controls/error-repeated-control.component';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from './assets/i18n/en_devel.json';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';
import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';
import { BusinessHoursComponent } from './controls/business-hours/business-hours.component';
import { SpecialHoursDisplayComponent } from './controls/business-hours/inner-components/special-hours-display/special-hours-display.component';
import { EditSpecialHoursDialogComponent } from './controls/business-hours/edit-special-hours-dialog/edit-special-hours-dialog.component';
import { EditSpecialTimePeriodComponent } from './controls/business-hours/inner-components/edit-special-time-period/edit-special-time-period.component';
import { EditTimeSpanComponent } from './controls/business-hours/inner-components/edit-time-span/edit-time-span.component';
import { GalaxyDatepickerModule } from '@vendasta/galaxy/datepicker';
import { GoogleAttributesComponent } from './controls/google-attributes/google-attributes.component';
import { BingAttributesComponent } from './controls/bing-attributes/bing-attributes.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { AttributesInputComponent } from './controls/google-attributes/attributes-input/attributes-input.component';
import { SpecialHoursComponent } from './controls/business-hours/special-hours/special-hours.component';
import { BooleanAttributeComponent } from './controls/google-attributes/attributes-input/boolean-attribute/boolean-attribute.component';
import { UrlAttributeComponent } from './controls/google-attributes/attributes-input/url-attribute/url-attribute.component';
import { TextAttributeComponent } from './controls/google-attributes/attributes-input/text-attribute/text-attribute.component';
import { RepeatedEnumAttributeComponent } from './controls/google-attributes/attributes-input/repeated-enum-attribute/repeated-enum-attribute.component';
import { SingleEnumAttributeComponent } from './controls/google-attributes/attributes-input/single-enum-attribute/single-enum-attribute.component';
import { EditRegularHoursDialogComponent } from './controls/business-hours/edit-regular-hours-dialog/edit-regular-hours-dialog.component';
import { RegularHoursDisplayComponent } from './controls/business-hours/inner-components/regular-hours-display/regular-hours-display.component';
import { GalaxyLoadingSpinnerModule } from '@vendasta/galaxy/loading-spinner';
import { InputTextareaAiComponent } from './controls/input-textarea-ai/input-textarea-ai.component';
import { GalaxyButtonGroupModule } from '@vendasta/galaxy/button-group';
import { GalaxyAITextButtonModule } from '@vendasta/galaxy/ai-text-button';

@NgModule({
  imports: [
    MatDateFnsModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    VaImageUploaderModule,
    VaFileUploaderModule,
    TranslateModule,
    MatFormFieldModule,
    LexiconModule.forChild({
      componentName: 'common/forms',
      baseTranslation: baseTranslation,
    }),
    GalaxyWrapModule,
    GalaxyFormFieldModule,
    GoogleMapsModule,
    GalaxyDatepickerModule,
    GalaxyLoadingSpinnerModule,
    MatButtonToggleModule,
    GalaxyButtonGroupModule,
    GalaxyAITextButtonModule,
  ],
  declarations: [
    InputComponent,
    InputRepeatedComponent,
    GlxyInputRepeatedComponent,
    InputTextareaComponent,
    DayOfWeekEditComponent,
    HoursOfOperationTimeSpanEditComponent,
    HoursOfOperationComponent,
    DayOfWeekDisplayComponent,
    EditHoursOfOperationDialogComponent,
    TimePickerComponent,
    InputTagsComponent,
    GlxyInputTagsComponent,
    GeoComponent,
    PhoneNumberMultiComponent,
    CountryStateComponent,
    TimeRangeSelectorComponent,
    ErrorControlDirective,
    ErrorRepeatedControlDirective,
    BusinessHoursComponent,
    SpecialHoursDisplayComponent,
    EditSpecialHoursDialogComponent,
    EditSpecialTimePeriodComponent,
    EditTimeSpanComponent,
    EditRegularHoursDialogComponent,
    GoogleAttributesComponent,
    BingAttributesComponent,
    AttributesInputComponent,
    BooleanAttributeComponent,
    UrlAttributeComponent,
    SingleEnumAttributeComponent,
    RepeatedEnumAttributeComponent,
    SpecialHoursComponent,
    EditRegularHoursDialogComponent,
    RegularHoursDisplayComponent,
    InputTextareaAiComponent,
    TextAttributeComponent,
  ],
  exports: [
    InputComponent,
    InputRepeatedComponent,
    GlxyInputRepeatedComponent,
    InputTextareaComponent,
    HoursOfOperationComponent,
    TimePickerComponent,
    InputTagsComponent,
    GlxyInputTagsComponent,
    GeoComponent,
    CountryStateComponent,
    VaImageUploaderModule,
    VaFileUploaderModule,
    PhoneNumberMultiComponent,
    TimeRangeSelectorComponent,
    BusinessHoursComponent,
    GoogleAttributesComponent,
    BingAttributesComponent,
    SpecialHoursComponent,
    InputTextareaAiComponent,
  ],
  providers: [FileUploadService],
})
export class VaFormsModule {}
