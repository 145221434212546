import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

// Material imports
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { GalaxyFormFieldModule } from '@vendasta/galaxy/form-field';

import { GalaxyCoreInputDirective, GetOptionGroupPipe, GetOptionPipe } from './core/core-input.directive';

import { InputComponent } from './input/input.component';
export { CurrencyFieldComponent } from './currency-field/currency-field.component';
export { CurrencyInputComponent } from './currency-input/currency-input.component';
export { EmailInputComponent } from './email-input/email-input.component';
export { InputComponent } from './input/input.component';
export { PasswordInputComponent } from './password-input/password-input.component';
export { ParsePhoneInputPipe } from './phone-input/parse-phone-input.pipe';
export { PhoneInputComponent } from './phone-input/phone-input.component';
export { SearchSelectInputComponent } from './search-select-input/search-select-input.component';
export { SelectInputComponent } from './select-input/select-input.component';

import { EmailInputComponent } from './email-input/email-input.component';

import { PasswordInputComponent } from './password-input/password-input.component';

import { PhoneInputComponent } from './phone-input/phone-input.component';

import { ParsePhoneInputPipe } from './phone-input/parse-phone-input.pipe';

import { SelectInputComponent } from './select-input/select-input.component';

import { CurrencyInputComponent } from './currency-input/currency-input.component';

import { CurrencyFieldComponent } from './currency-field/currency-field.component';

import { SearchSelectInputComponent } from './search-select-input/search-select-input.component';

export const MODULE_IMPORTS = [
  CommonModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  FormsModule,
  ReactiveFormsModule,
  MatSelectModule,
  TranslateModule,
  MatMenuModule,
  MatTooltipModule,
  MatAutocompleteModule,
  GalaxyFormFieldModule,
];

export const MODULE_DECLARATIONS = [
  InputComponent,
  GalaxyCoreInputDirective,
  EmailInputComponent,
  PasswordInputComponent,
  PhoneInputComponent,
  ParsePhoneInputPipe,
  SelectInputComponent,
  CurrencyInputComponent,
  CurrencyFieldComponent,
  SearchSelectInputComponent,
  GetOptionPipe,
  GetOptionGroupPipe,
];

export const MODULE_EXPORTS = [
  InputComponent,
  EmailInputComponent,
  PasswordInputComponent,
  PhoneInputComponent,
  SelectInputComponent,
  CurrencyInputComponent,
  CurrencyFieldComponent,
  SearchSelectInputComponent,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  providers: [GetOptionPipe, GetOptionGroupPipe],
  exports: MODULE_EXPORTS,
  imports: MODULE_IMPORTS,
})
export class GalaxyInputModule {}
