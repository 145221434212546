import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';

import { TranslateModule } from '@ngx-translate/core';

import { GalaxyInputModule } from '@vendasta/galaxy/input';
import { GalaxyWrapModule } from '@vendasta/galaxy/galaxy-wrap';

import { DatepickerComponent } from './datepicker/datepicker.component';
import { DateRangePresetsComponent } from './date-range-presets/date-range-presets.component';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';

export const MODULE_IMPORTS = [
  MatDateFnsModule,
  CommonModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatButtonModule,
  MatDividerModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  OverlayModule,
  TranslateModule,
  FormsModule,
  ReactiveFormsModule,
  GalaxyInputModule,
  GalaxyWrapModule,
];

export const MODULE_DECLARATIONS = [DatepickerComponent, DateRangePresetsComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: MODULE_DECLARATIONS,
})
export class GalaxyDatepickerModule {}
