{
  "FRONTEND": {
    "FORMS": {
      "ATTRIBUTES": {
        "CARD_TITLE": "Google",
        "SELECT_BUSINESS_CATEGORY": "Select a business category",
        "TO_ACCESS_ATTRIBUTES": "to access attributes",
        "AUTOCOMPLETE_HEADER": "Add attributes",
        "MORE_ATTRIBUTES": "Attributes"
      },
      "INPUT": {
        "YES": "Yes",
        "NO": "No",
        "REQUIRED": "Required",
        "WHATS_THIS": "What's this?",
        "MAXIMUM_LENGTH": "Maximum length of {{maxLength}}",
        "MINIMUM_LENGTH": "Minimum length of {{minLength}}",
        "ADD_FIELD": "+ Add field",
        "MAX_LENGTH_ERROR": "You can only choose {{maxLength}} options",
        "ADD": "Add"
      },
      "MORE_HOURS": {
        "TITLE": "More hours",
        "EMPTY": "More hours aren't applicable to your business category"
      },
      "SPECIAL_HOURS": {
        "DESCRIPTION_ADD_EXCEPTIONS": "Add exceptions to your regular hours for upcoming holidays and other special cases.",
        "TITLE": "Special Hours",
        "DATE": "Date",
        "OPEN": "Open",
        "ADD_NEW_DATE": "Add new date",
        "ADD_HOURS": "Add hours",
        "REMOVE_DATE": "Remove Date"
      },
      "HOURS_OF_OPERATION": {
        "HOURS_OF_OPERATION": "Hours of operation",
        "MONDAY": "Monday",
        "TUESDAY": "Tuesday",
        "WEDNESDAY": "Wednesday",
        "THURSDAY": "Thursday",
        "FRIDAY": "Friday",
        "SATURDAY": "Saturday",
        "SUNDAY": "Sunday",
        "HOLIDAY_HOURS": "Holiday hours",
        "PUBLIC_HOLIDAYS": "Public holidays",
        "CLOSED": "Closed",
        "NONE": "None",
        "ALL_DAY": "All day",
        "NOTES": "Notes",
        "ADD_NOTES": "Add notes",
        "ADD_HOURS": "Add hours",
        "NOTES_ONLY": "Notes only",
        "OPENS_AT": "Opens at",
        "CLOSES_AT": "Closes at",
        "CANCEL": "Cancel",
        "SAVE": "Save",
        "REMOVE": "Remove hours",
        "REMOVE_DATE": "Remove Date",
        "HOURS_CANNOT_BE_EMPTY": "Hours cannot be empty",
        "CLOSING_TIME_MUST_BE_AFTER_OPENING": "Closing time must be after opening time. If you meant 'open all day', set the opening time to 'All day'",
        "OPENING_TIME_OVER_MIDNIGHT": "If your business is open over midnight, you'll need to set your hours for each day. For example, if open from 9 PM - 2 AM, enter 9 PM - 12 AM on the first day and 12 AM - 2 AM on the second.",
        "NOTES_ONLY_IS_DEPRECATED": "You must specify hours of operation for this day. Availability by appointment only can be set under the <strong>Hours</strong> tab in your <strong>Business Profile</strong>. Other details can be added to your <strong>Business Descriptions</strong> in the <strong>Marketing</strong> tab.",
        "DESCRIPTION_WILL_BE_REMOVED": "Notes on individual days are no longer supported. Please add relevant notes to your <strong>Business Descriptions</strong> under the <strong>Marketing</strong> tab."
      },
      "IMAGE_UPLOAD": {
        "DROP_NOUN_HERE": "Drop {{imageNoun}} here",
        "UPLOAD_NOUN": "Upload {{imageNoun}}",
        "DISABLED": "Disabled",
        "OR": "or",
        "MAX_FILE_SIZE": "Maximum file size {{sizeInMb}} MB",
        "IMAGE_NOT_DETECTED": "Image not detected",
        "UNSUPPORTED_FILE_TYPE": "Unsupported file type {{fileType}}, please try a different file.",
        "FILE_TOO_LARGE": "File is too large, please try a different file.",
        "NO_UPLOAD_URL_SET": "No upload url set",
        "DRAG_AND_DROP_UNSUPPORTED": "Your browser does not support drag and drop. Select 'Upload Image' instead."
      },
      "TIME_PICKER": {
        "ENTER_VALID_TIME": "Enter valid time"
      }
    },
    "BUSINESS": {
      "ACTIVE": "Active"
    }
  }
}
