import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { FileUploadService } from '../va-file-upload/file-upload.service';
import { ImageUploaderComponent } from './image-upload.component';
import { LexiconModule } from '@galaxy/lexicon';
import baseTranslation from '../../assets/i18n/en_devel.json';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    LexiconModule.forChild({
      componentName: 'common/forms',
      baseTranslation: baseTranslation,
    }),
  ],
  declarations: [ImageUploaderComponent],
  providers: [FileUploadService],
  exports: [ImageUploaderComponent],
})
export class VaImageUploaderModule {}
