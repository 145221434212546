import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUploadInfo } from './file-upload-info';

@Injectable()
export class FileUploadService {
  constructor(private http: HttpClient) {}

  upload(url: string, file: File): Observable<FileUploadInfo> {
    const body = new FormData();
    body.append('file', file);
    return this.http
      .post(url, body, { withCredentials: true })
      .pipe(map((response: any) => response.data)) as Observable<FileUploadInfo>;
  }
}
