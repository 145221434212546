import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonGroupComponent } from './button-group.component';
export { ButtonGroupComponent } from './button-group.component';

export const MODULE_IMPORTS = [CommonModule];

export const MODULE_DECLARATIONS = [ButtonGroupComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [ButtonGroupComponent],
})
export class GalaxyButtonGroupModule {}
