import { UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

export interface GalaxyInputValidator {
  validatorFn: ValidatorFn;
  errorMessage: string;
}

export interface GalaxyAsyncInputValidator {
  validatorFn: (control: UntypedFormControl) => Observable<ValidationErrors>;
  errorMessage: string;
}

export interface SelectInputOptionInterface<Value = any> {
  value: Value;
  label: string;
  description?: string;
  selected?: boolean;
  disabled?: boolean;
}

export interface SelectInputOptionGroupInterface {
  label: string;
  disabled?: boolean;
  options: SelectInputOptionInterface[];
}

export type SelectInputOption = SelectInputOptionInterface | SelectInputOptionGroupInterface;

export interface GalaxyCoreInputInterface {
  id?: string;
  label?: string;
  placeholder?: string;
  formControl?: UntypedFormControl;
  required?: boolean;
  validators?: GalaxyInputValidator[];
  disabled?: boolean;
}

export interface GalaxyInputInterface extends GalaxyCoreInputInterface {
  trailingIcon?: string;
  iconClickable?: boolean;
  hint?: string;
}

export interface GalaxySelectInputInterface extends GalaxyCoreInputInterface {
  hint?: string;
  options?: SelectInputOption[];
}

export interface GalaxyPasswordInputInterface extends GalaxyCoreInputInterface {
  showPasswordStrength?: boolean;
  confirmPassword?: boolean;
}

export interface GalaxyCurrencyInputInterface extends GalaxyCoreInputInterface {
  hint?: string;
  currencyCode?: string;
  decimalSeparator?: string;
  digitGroupSeparator?: string;
  decimalPlaces?: number;
  maximumValue?: string;
  minimumValue?: string;
  locale?: string;
}

export interface GalaxyCurrencyFieldInterface extends GalaxyCoreInputInterface {
  hint?: string;
  currencyCode?: string;
  decimalSeparator?: string;
  digitGroupSeparator?: string;
  decimalPlaces?: number;
  maximumValue?: string;
  minimumValue?: string;
  locale?: string;
}

// Docs on these types can be found here: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/
// Note that these are input types compatible with MatInput
export enum InputType {
  Color = 'color',
  Date = 'date',
  DateTimeLocal = 'datetime-local',
  Month = 'month',
  Number = 'number',
  Search = 'search',
  Text = 'text',
  Time = 'time',
  URL = 'url',
  Week = 'week',
}
