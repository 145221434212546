<mat-form-field
  class="galaxy-form-field"
  [ngClass]="{
    'no-hint-message': !formControl.invalid || formControl.pristine,
  }"
  appearance="outline"
>
  @if (!!label) {
    <mat-label>{{ label | translate }}</mat-label>
  }
  <input
    matInput
    [id]="id || 'password'"
    [name]="id || 'password'"
    [formControl]="formControl"
    placeholder="{{ placeholder | translate }}"
    [required]="!!required"
    [type]="currentType"
    (change)="writeValue($event.target.value || '')"
    (blur)="onBlur()"
  />
  <mat-icon class="glxy-icon-clickable" matSuffix (click)="togglePasswordVisibility()">
    {{ currentIcon }}
  </mat-icon>

  @if (validatorError$ | async; as validatorError) {
    <mat-error>
      @if (validatorError !== '') {
        {{ validatorError | translate }}
      }
    </mat-error>
  }
</mat-form-field>
@if (showPasswordStrength && formControl) {
  <div class="galaxy-password-strength">
    @if (passwordStrength$ | async; as strength) {
      <div class="strength-title">
        {{ 'GALAXY.INPUT.PASSWORD.PASS_STRENGTH' | translate }}:
        <span>{{ (strengthLabel$ | async) ?? '' | translate }}</span>
      </div>
      <div class="strength-bar-container strength-{{ strength }}">
        @for (x of [1, 2, 3]; track x) {
          <div class="strength-bar"></div>
        }
      </div>
    }
  </div>
}
@if (confirmPassword) {
  <mat-form-field
    class="galaxy-form-field"
    [ngClass]="{
      'no-hint-message': !confirmPasswordFormControl.invalid || confirmPasswordFormControl.pristine,
    }"
    appearance="outline"
  >
    @if (!!label) {
      <mat-label>{{ 'GALAXY.INPUT.PASSWORD.CONFIRM' | translate }} {{ label | translate }}</mat-label>
    }
    <input
      matInput
      [id]="'confirm-' + (id || 'password')"
      [name]="'confirm-' + (id || 'password')"
      [formControl]="confirmPasswordFormControl"
      placeholder="{{ placeholder | translate }}"
      [required]="!!required"
      [type]="currentType"
      (blur)="onBlur()"
    />
    <mat-icon class="glxy-icon-clickable" matSuffix (click)="togglePasswordVisibility()">
      {{ currentIcon }}
    </mat-icon>
    @if (validatorError$ | async; as validatorError) {
      <mat-error>
        @if (validatorError !== '') {
          {{ validatorError | translate }}
        }
      </mat-error>
    }
  </mat-form-field>
}
