import { booleanAttribute, Directive, Input, Pipe, PipeTransform } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, tap } from 'rxjs/operators';
import {
  GalaxyAsyncInputValidator,
  GalaxyCoreInputInterface,
  GalaxyInputValidator,
  SelectInputOption,
  SelectInputOptionGroupInterface,
  SelectInputOptionInterface,
} from '../input.interface';

@Directive({
  selector: '[glxyCoreInput]',
})
export class GalaxyCoreInputDirective<T> implements ControlValueAccessor {
  static ASYNC_DEBOUNCE_DELAY = 100;

  /** Id of the field */
  @Input() id = '';
  /** Label to display context on what type of text should be entered into the field */
  @Input() label = '';
  /** Placeholder text as an example of what text should be entered into the field */
  @Input() placeholder = '';
  /** The form control for the input. If no form control is passed in, it will create its own */
  @Input() formControl: UntypedFormControl = new UntypedFormControl();
  /** If true, requires the field be filled with text by the user */
  @Input({ transform: booleanAttribute }) required = false;
  /** Whether or not to disable autocompletion for forms */
  @Input({ transform: booleanAttribute }) disableAutoComplete = false;
  /** [Advanced] List of GalaxyInputValidators */
  @Input() validators: GalaxyInputValidator[] = [];
  /** [Advanced] List of GalaxyInputValidators */
  @Input() asyncValidators: GalaxyAsyncInputValidator[] = [];
  /** Controls if the input is disabled */
  @Input({ transform: booleanAttribute }) set disabled(disabled: boolean) {
    this.setDisabledState(!!disabled);
  }
  /** Allows for value input, similar to other angular form inputs */
  @Input() set value(val: T) {
    if (val && typeof val === 'object' && 'toString' in val) {
      this.formControl.setValue(val.toString());
    } else {
      this.formControl.setValue(val);
    }
    this.formControl.updateValueAndValidity();
  }

  @Input() config?: GalaxyCoreInputInterface;

  @Input() size?: string;

  @Input() bottomSpacing?: 'default' | 'small' | 'none' | false = 'default';

  inputValue?: T;
  isDisabled = false;

  asyncValidatorErrors$$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  asyncValidatorErrors$: Observable<string[]> = new Observable<string[]>();
  validatorError$?: Observable<string>;

  // Disables eslint on next line since the onChange function is meant to be overridden
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (_value: T): void => {
    // this should be completed to properly implement ControlValueAccessor
  };

  // Disables eslint on next line since the onTouched function is meant to be overridden
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onTouched = (_value: T): void => {
    // this should be completed to properly implement ControlValueAccessor
  };

  setupControl(): void {
    // If the config is passed in, parse it first
    if (this.config) {
      this.id = this.config.id || '';
      this.label = this.config.label || '';
      this.placeholder = this.config.placeholder || '';
      this.formControl = this.config.formControl || new UntypedFormControl();
      this.required = this.config.required || false;
      this.validators = this.config.validators || [];
      this.disabled = this.config.disabled || false;
    }

    // Drop in required validation
    if (this.required) {
      // make sure that a req validator hasn't already been added
      const alreadyContainsReq = this.validators?.some((validator) => {
        return validator.validatorFn === Validators.required;
      });

      if (!alreadyContainsReq) {
        this.validators?.push({
          validatorFn: Validators.required,
          errorMessage: 'GALAXY.INPUT.CORE.VALIDATION_ERROR_REQ',
        });
      }
    }

    const validators = this.validators?.map((validator) => {
      return (control: AbstractControl): ValidationErrors | null => {
        if (!validator.validatorFn(control)) {
          return null;
        }

        return { message: validator.errorMessage };
      };
    });

    if (validators) this.formControl.setValidators(validators);
    if (this.asyncValidators?.length !== 0) {
      this.asyncValidatorErrors$ = this.formControl.valueChanges.pipe(
        debounceTime(GalaxyCoreInputDirective.ASYNC_DEBOUNCE_DELAY),
        distinctUntilChanged(),
        mergeMap(() => this.runAsyncValidators()),
        map((result) => this.handleAsyncValidatorResults(result)),
        tap((result) => this.asyncValidatorErrors$$.next(result)),
      );

      this.validatorError$ = combineLatest([this.formControl.statusChanges, this.asyncValidatorErrors$]).pipe(
        map(([, asyncErrors]) => {
          if (asyncErrors.length > 0) {
            return asyncErrors[0];
          }
          return this.formControl.errors ? this.formControl.errors.message : '';
        }),
      );
    } else {
      this.validatorError$ = this.formControl.statusChanges.pipe(
        map(() => {
          return this.formControl.errors ? this.formControl.errors.message : '';
        }),
      );
    }
    this.formControl.updateValueAndValidity();
  }

  runAsyncValidators(): Observable<{ validatorResult: ValidationErrors; errorMessage: string }[]> {
    const asyncValidators = this.asyncValidators.map((validator) => {
      return validator.validatorFn(this.formControl);
    });

    return combineLatest(asyncValidators).pipe(
      map((results) => {
        return this.asyncValidators.map((val, index) => ({
          validatorResult: results[index],
          errorMessage: val.errorMessage,
        }));
      }),
    );
  }

  handleAsyncValidatorResults(results: { validatorResult: ValidationErrors; errorMessage: string }[]): string[] {
    const filteredResults = results.filter((r) => r.validatorResult);
    const asyncHasErrors = results.some((r) => r.validatorResult);
    const errorMessages = filteredResults.map((r) => r.errorMessage);
    this.collectErrorsAndUpdateForm(filteredResults);
    return asyncHasErrors ? errorMessages : [];
  }

  collectErrorsAndUpdateForm(errors: ValidationErrors[]): void {
    const allErrorsMap: ValidationErrors = {};
    if (this.formControl.errors) errors.push(this.formControl.errors);
    errors.forEach((errorSet) => {
      if (errorSet) {
        Object.keys(errorSet).forEach((key: string) => (allErrorsMap[key] = errorSet[key]));
      }
    });
    if (Object.keys(allErrorsMap).length > 0) {
      this.formControl.setErrors(allErrorsMap);
    }
  }

  onBlur(): void {
    if (!this.asyncValidatorErrors$$.getValue().length) {
      this.formControl.updateValueAndValidity();
    }
  }

  writeValue(value: T): void {
    this.inputValue = value;
    this.onChange(value);
  }

  registerOnChange(fn: (value: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (value: T) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Do nothing when no change
    if (isDisabled === this.formControl?.disabled) {
      return;
    }

    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}

@Pipe({
  name: 'getOption',
})
export class GetOptionPipe implements PipeTransform {
  transform(obj: SelectInputOption): SelectInputOptionInterface | null {
    if ('options' in obj) {
      return null;
    }
    return obj as SelectInputOptionInterface;
  }
}

@Pipe({
  name: 'getOptionGroup',
})
export class GetOptionGroupPipe implements PipeTransform {
  transform(obj: SelectInputOption): SelectInputOptionGroupInterface | null {
    if ('options' in obj) {
      return obj as SelectInputOptionGroupInterface;
    }
    return null;
  }
}
