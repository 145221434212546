export interface CountryDetailsInterface {
  countryCode: string;
  flag: string;
  countryNameEn: string;
  countryNameLocal: string;
  countryCallingCode: number;
}

export const CountryCodeList: CountryDetailsInterface[] = [
  {
    countryCode: 'AF',
    countryNameEn: 'Afghanistan',
    countryNameLocal: 'د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان',
    countryCallingCode: 93,
    flag: '🇦🇫',
  },
  {
    countryCode: 'AL',
    countryNameEn: 'Albania',
    countryNameLocal: 'Shqipëria',
    countryCallingCode: 355,
    flag: '🇦🇱',
  },
  {
    countryCode: 'DZ',
    countryNameEn: 'Algeria',
    countryNameLocal: 'الجزائر',
    countryCallingCode: 213,
    flag: '🇩🇿',
  },
  {
    countryCode: 'AS',
    countryNameEn: 'American Samoa',
    countryNameLocal: 'American Samoa',
    countryCallingCode: 1684,
    flag: '🇦🇸',
  },
  {
    countryCode: 'AD',
    countryNameEn: 'Andorra',
    countryNameLocal: 'Andorra',
    countryCallingCode: 376,
    flag: '🇦🇩',
  },
  {
    countryCode: 'AO',
    countryNameEn: 'Angola',
    countryNameLocal: 'Angola',
    countryCallingCode: 244,
    flag: '🇦🇴',
  },
  {
    countryCode: 'AI',
    countryNameEn: 'Anguilla',
    countryNameLocal: 'Anguilla',
    countryCallingCode: 1264,
    flag: '🇦🇮',
  },
  {
    countryCode: 'AQ',
    countryNameEn: 'Antarctica',
    countryNameLocal: 'Antarctica, Antártico, Antarctique, Антарктике',
    countryCallingCode: 672,
    flag: '🇦🇶',
  },
  {
    countryCode: 'AG',
    countryNameEn: 'Antigua and Barbuda',
    countryNameLocal: 'Antigua and Barbuda',
    countryCallingCode: 1268,
    flag: '🇦🇬',
  },
  {
    countryCode: 'AR',
    countryNameEn: 'Argentina',
    countryNameLocal: 'Argentina',
    countryCallingCode: 54,
    flag: '🇦🇷',
  },
  {
    countryCode: 'AM',
    countryNameEn: 'Armenia',
    countryNameLocal: 'Հայաստան',
    countryCallingCode: 374,
    flag: '🇦🇲',
  },
  {
    countryCode: 'AW',
    countryNameEn: 'Aruba',
    countryNameLocal: 'Aruba',
    countryCallingCode: 297,
    flag: '🇦🇼',
  },
  {
    countryCode: 'AU',
    countryNameEn: 'Australia',
    countryNameLocal: 'Australia',
    countryCallingCode: 61,
    flag: '🇦🇺',
  },
  {
    countryCode: 'AT',
    countryNameEn: 'Austria',
    countryNameLocal: 'Österreich',
    countryCallingCode: 43,
    flag: '🇦🇹',
  },
  {
    countryCode: 'AZ',
    countryNameEn: 'Azerbaijan',
    countryNameLocal: 'Azərbaycan',
    countryCallingCode: 994,
    flag: '🇦🇿',
  },
  {
    countryCode: 'BH',
    countryNameEn: 'Bahrain',
    countryNameLocal: 'البحرين',
    countryCallingCode: 973,
    flag: '🇧🇭',
  },
  {
    countryCode: 'BD',
    countryNameEn: 'Bangladesh',
    countryNameLocal: 'গণপ্রজাতন্ত্রী বাংলাদেশ',
    countryCallingCode: 880,
    flag: '🇧🇩',
  },
  {
    countryCode: 'BB',
    countryNameEn: 'Barbados',
    countryNameLocal: 'Barbados',
    countryCallingCode: 1246,
    flag: '🇧🇧',
  },
  {
    countryCode: 'BY',
    countryNameEn: 'Belarus',
    countryNameLocal: 'Беларусь',
    countryCallingCode: 375,
    flag: '🇧🇾',
  },
  {
    countryCode: 'BE',
    countryNameEn: 'Belgium',
    countryNameLocal: 'België, Belgique, Belgien',
    countryCallingCode: 32,
    flag: '🇧🇪',
  },
  {
    countryCode: 'BZ',
    countryNameEn: 'Belize',
    countryNameLocal: 'Belize',
    countryCallingCode: 501,
    flag: '🇧🇿',
  },
  {
    countryCode: 'BJ',
    countryNameEn: 'Benin',
    countryNameLocal: 'Bénin',
    countryCallingCode: 229,
    flag: '🇧🇯',
  },
  {
    countryCode: 'BM',
    countryNameEn: 'Bermuda',
    countryNameLocal: 'Bermuda',
    countryCallingCode: 1441,
    flag: '🇧🇲',
  },
  {
    countryCode: 'BT',
    countryNameEn: 'Bhutan',
    countryNameLocal: 'འབྲུག་ཡུལ',
    countryCallingCode: 975,
    flag: '🇧🇹',
  },
  {
    countryCode: 'BO',
    countryNameEn: 'Bolivia (Plurinational State of)',
    countryNameLocal: 'Bolivia, Bulibiya, Volívia, Wuliwya',
    countryCallingCode: 591,
    flag: '🇧🇴',
  },
  {
    countryCode: 'BQ',
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
    countryNameLocal: 'Caribisch Nederland',
    countryCallingCode: 5997,
    flag: '🇧🇶',
  },
  {
    countryCode: 'BA',
    countryNameEn: 'Bosnia and Herzegovina',
    countryNameLocal: 'Bosna i Hercegovina',
    countryCallingCode: 387,
    flag: '🇧🇦',
  },
  {
    countryCode: 'BW',
    countryNameEn: 'Botswana',
    countryNameLocal: 'Botswana',
    countryCallingCode: 267,
    flag: '🇧🇼',
  },
  {
    countryCode: 'BV',
    countryNameEn: 'Bouvet Island',
    countryNameLocal: 'Bouvetøya',
    countryCallingCode: 47,
    flag: '🇧🇻',
  },
  {
    countryCode: 'BR',
    countryNameEn: 'Brazil',
    countryNameLocal: 'Brasil',
    countryCallingCode: 55,
    flag: '🇧🇷',
  },
  {
    countryCode: 'IO',
    countryNameEn: 'British Indian Ocean Territory',
    countryNameLocal: 'British Indian Ocean Territory',
    countryCallingCode: 246,
    flag: '🇮🇴',
  },
  {
    countryCode: 'BN',
    countryNameEn: 'Brunei Darussalam',
    countryNameLocal: 'Brunei Darussalam',
    countryCallingCode: 673,
    flag: '🇧🇳',
  },
  {
    countryCode: 'BG',
    countryNameEn: 'Bulgaria',
    countryNameLocal: 'България',
    countryCallingCode: 359,
    flag: '🇧🇬',
  },
  {
    countryCode: 'BF',
    countryNameEn: 'Burkina Faso',
    countryNameLocal: 'Burkina Faso',
    countryCallingCode: 226,
    flag: '🇧🇫',
  },
  {
    countryCode: 'BI',
    countryNameEn: 'Burundi',
    countryNameLocal: 'Burundi',
    countryCallingCode: 257,
    flag: '🇧🇮',
  },
  {
    countryCode: 'CV',
    countryNameEn: 'Cabo Verde',
    countryNameLocal: 'Cabo Verde',
    countryCallingCode: 238,
    flag: '🇨🇻',
  },
  {
    countryCode: 'KH',
    countryNameEn: 'Cambodia',
    countryNameLocal: 'កម្ពុជា',
    countryCallingCode: 855,
    flag: '🇰🇭',
  },
  {
    countryCode: 'CM',
    countryNameEn: 'Cameroon',
    countryNameLocal: 'Cameroun, Cameroon',
    countryCallingCode: 237,
    flag: '🇨🇲',
  },
  {
    countryCode: 'CA',
    countryNameEn: 'Canada',
    countryNameLocal: 'Canada',
    countryCallingCode: 1,
    flag: '🇨🇦',
  },
  {
    countryCode: 'KY',
    countryNameEn: 'Cayman Islands',
    countryNameLocal: 'Cayman Islands',
    countryCallingCode: 1345,
    flag: '🇰🇾',
  },
  {
    countryCode: 'CF',
    countryNameEn: 'Central African Republic',
    countryNameLocal: 'République centrafricaine',
    countryCallingCode: 236,
    flag: '🇨🇫',
  },
  {
    countryCode: 'TD',
    countryNameEn: 'Chad',
    countryNameLocal: 'Tchad, تشاد',
    countryCallingCode: 235,
    flag: '🇹🇩',
  },
  {
    countryCode: 'CL',
    countryNameEn: 'Chile',
    countryNameLocal: 'Chile',
    countryCallingCode: 56,
    flag: '🇨🇱',
  },
  {
    countryCode: 'CN',
    countryNameEn: 'China',
    countryNameLocal: '中国',
    countryCallingCode: 86,
    flag: '🇨🇳',
  },
  {
    countryCode: 'CX',
    countryNameEn: 'Christmas Island',
    countryNameLocal: 'Christmas Island',
    countryCallingCode: 61,
    flag: '🇨🇽',
  },
  {
    countryCode: 'CC',
    countryNameEn: 'Cocos (Keeling) Islands',
    countryNameLocal: 'Pulu Kokos (Keeling)',
    countryCallingCode: 61891,
    flag: '🇨🇨',
  },
  {
    countryCode: 'CO',
    countryNameEn: 'Colombia',
    countryNameLocal: 'Colombia',
    countryCallingCode: 57,
    flag: '🇨🇴',
  },
  {
    countryCode: 'BS',
    countryNameEn: 'Commonwealth of The Bahamas',
    countryNameLocal: 'Commonwealth of The Bahamas',
    countryCallingCode: 1242,
    flag: '🇧🇸',
  },
  {
    countryCode: 'MP',
    countryNameEn: 'Commonwealth of the Northern Mariana Islands',
    countryNameLocal: 'Sankattan Siha Na Islas Mariånas',
    countryCallingCode: 1670,
    flag: '🇲🇵',
  },
  {
    countryCode: 'KM',
    countryNameEn: 'Comoros',
    countryNameLocal: 'Umoja wa Komori',
    countryCallingCode: 269,
    flag: '🇰🇲',
  },
  {
    countryCode: 'CK',
    countryNameEn: 'Cook Islands',
    countryNameLocal: "Kūki 'Āirani",
    countryCallingCode: 682,
    flag: '🇨🇰',
  },
  {
    countryCode: 'CR',
    countryNameEn: 'Costa Rica',
    countryNameLocal: 'Costa Rica',
    countryCallingCode: 506,
    flag: '🇨🇷',
  },
  {
    countryCode: 'HR',
    countryNameEn: 'Croatia',
    countryNameLocal: 'Hrvatska',
    countryCallingCode: 385,
    flag: '🇭🇷',
  },
  {
    countryCode: 'CU',
    countryNameEn: 'Cuba',
    countryNameLocal: 'Cuba',
    countryCallingCode: 53,
    flag: '🇨🇺',
  },
  {
    countryCode: 'CW',
    countryNameEn: 'Curaçao',
    countryNameLocal: 'Curaçao',
    countryCallingCode: 599,
    flag: '🇨🇼',
  },
  {
    countryCode: 'CY',
    countryNameEn: 'Cyprus',
    countryNameLocal: 'Κύπρος, Kibris',
    countryCallingCode: 357,
    flag: '🇨🇾',
  },
  {
    countryCode: 'CZ',
    countryNameEn: 'Czechia',
    countryNameLocal: 'Česká republika',
    countryCallingCode: 420,
    flag: '🇨🇿',
  },
  {
    countryCode: 'CI',
    countryNameEn: "Côte d'Ivoire",
    countryNameLocal: "Côte d'Ivoire",
    countryCallingCode: 225,
    flag: '🇨🇮',
  },
  {
    countryCode: 'CD',
    countryNameEn: 'Democratic Republic of the Congo',
    countryNameLocal: 'Democratic Republic of the Congo',
    countryCallingCode: 243,
    flag: '🇨🇩',
  },
  {
    countryCode: 'DK',
    countryNameEn: 'Denmark',
    countryNameLocal: 'Danmark',
    countryCallingCode: 45,
    flag: '🇩🇰',
  },
  {
    countryCode: 'DJ',
    countryNameEn: 'Djibouti',
    countryNameLocal: 'Djibouti, جيبوتي, Jabuuti, Gabuutih',
    countryCallingCode: 253,
    flag: '🇩🇯',
  },
  {
    countryCode: 'DM',
    countryNameEn: 'Dominica',
    countryNameLocal: 'Dominica',
    countryCallingCode: 767,
    flag: '🇩🇲',
  },
  {
    countryCode: 'DO',
    countryNameEn: 'Dominican Republic',
    countryNameLocal: 'República Dominicana',
    countryCallingCode: 1,
    flag: '🇩🇴',
  },
  {
    countryCode: 'EC',
    countryNameEn: 'Ecuador',
    countryNameLocal: 'Ecuador',
    countryCallingCode: 593,
    flag: '🇪🇨',
  },
  {
    countryCode: 'EG',
    countryNameEn: 'Egypt',
    countryNameLocal: 'مصر',
    countryCallingCode: 20,
    flag: '🇪🇬',
  },
  {
    countryCode: 'SV',
    countryNameEn: 'El Salvador',
    countryNameLocal: 'El Salvador',
    countryCallingCode: 503,
    flag: '🇸🇻',
  },
  {
    countryCode: 'GQ',
    countryNameEn: 'Equatorial Guinea',
    countryNameLocal: 'Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial',
    countryCallingCode: 240,
    flag: '🇬🇶',
  },
  {
    countryCode: 'ER',
    countryNameEn: 'Eritrea',
    countryNameLocal: 'ኤርትራ, إرتريا, Eritrea',
    countryCallingCode: 291,
    flag: '🇪🇷',
  },
  {
    countryCode: 'EE',
    countryNameEn: 'Estonia',
    countryNameLocal: 'Eesti',
    countryCallingCode: 372,
    flag: '🇪🇪',
  },
  {
    countryCode: 'SZ',
    countryNameEn: 'Eswatini',
    countryNameLocal: 'Swaziland',
    countryCallingCode: 268,
    flag: '🇸🇿',
  },
  {
    countryCode: 'ET',
    countryNameEn: 'Ethiopia',
    countryNameLocal: 'ኢትዮጵያ, Itoophiyaa',
    countryCallingCode: 251,
    flag: '🇪🇹',
  },
  {
    countryCode: 'FK',
    countryNameEn: 'Falkland Islands',
    countryNameLocal: 'Falkland Islands',
    countryCallingCode: 500,
    flag: '🇫🇰',
  },
  {
    countryCode: 'FO',
    countryNameEn: 'Faroe Islands',
    countryNameLocal: 'Færøerne',
    countryCallingCode: 298,
    flag: '🇫🇴',
  },
  {
    countryCode: 'FJ',
    countryNameEn: 'Fiji',
    countryNameLocal: 'Fiji',
    countryCallingCode: 679,
    flag: '🇫🇯',
  },
  {
    countryCode: 'FI',
    countryNameEn: 'Finland',
    countryNameLocal: 'Suomi',
    countryCallingCode: 358,
    flag: '🇫🇮',
  },
  {
    countryCode: 'FR',
    countryNameEn: 'France',
    countryNameLocal: 'France',
    countryCallingCode: 33,
    flag: '🇫🇷',
  },
  {
    countryCode: 'GF',
    countryNameEn: 'French Guiana',
    countryNameLocal: 'Guyane française',
    countryCallingCode: 594,
    flag: '🇬🇫',
  },
  {
    countryCode: 'PF',
    countryNameEn: 'French Polynesia',
    countryNameLocal: 'Polynésie française',
    countryCallingCode: 689,
    flag: '🇵🇫',
  },
  {
    countryCode: 'TF',
    countryNameEn: 'French Southern and Antarctic Lands',
    countryNameLocal: 'Terres australes et antarctiques françaises',
    countryCallingCode: 672,
    flag: '🇹🇫',
  },
  {
    countryCode: 'GA',
    countryNameEn: 'Gabon',
    countryNameLocal: 'Gabon',
    countryCallingCode: 241,
    flag: '🇬🇦',
  },
  {
    countryCode: 'GM',
    countryNameEn: 'Gambia',
    countryNameLocal: 'The Gambia',
    countryCallingCode: 220,
    flag: '🇬🇲',
  },
  {
    countryCode: 'GE',
    countryNameEn: 'Georgia',
    countryNameLocal: 'საქართველო',
    countryCallingCode: 995,
    flag: '🇬🇪',
  },
  {
    countryCode: 'DE',
    countryNameEn: 'Germany',
    countryNameLocal: 'Deutschland',
    countryCallingCode: 49,
    flag: '🇩🇪',
  },
  {
    countryCode: 'GH',
    countryNameEn: 'Ghana',
    countryNameLocal: 'Ghana',
    countryCallingCode: 233,
    flag: '🇬🇭',
  },
  {
    countryCode: 'GI',
    countryNameEn: 'Gibraltar',
    countryNameLocal: 'Gibraltar',
    countryCallingCode: 350,
    flag: '🇬🇮',
  },
  {
    countryCode: 'GR',
    countryNameEn: 'Greece',
    countryNameLocal: 'Ελλάδα',
    countryCallingCode: 30,
    flag: '🇬🇷',
  },
  {
    countryCode: 'GL',
    countryNameEn: 'Greenland',
    countryNameLocal: 'Kalaallit Nunaat, Grønland',
    countryCallingCode: 299,
    flag: '🇬🇱',
  },
  {
    countryCode: 'GD',
    countryNameEn: 'Grenada',
    countryNameLocal: 'Grenada',
    countryCallingCode: 1473,
    flag: '🇬🇩',
  },
  {
    countryCode: 'GP',
    countryNameEn: 'Guadeloupe',
    countryNameLocal: 'Guadeloupe',
    countryCallingCode: 590,
    flag: '🇬🇵',
  },
  {
    countryCode: 'GU',
    countryNameEn: 'Guam',
    countryNameLocal: 'Guam, Guåhån',
    countryCallingCode: 1,
    flag: '🇬🇺',
  },
  {
    countryCode: 'GT',
    countryNameEn: 'Guatemala',
    countryNameLocal: 'Guatemala',
    countryCallingCode: 502,
    flag: '🇬🇹',
  },
  {
    countryCode: 'GG',
    countryNameEn: 'Guernsey',
    countryNameLocal: 'Guernsey',
    countryCallingCode: 44,
    flag: '🇬🇬',
  },
  {
    countryCode: 'GN',
    countryNameEn: 'Guinea',
    countryNameLocal: 'Guinée',
    countryCallingCode: 224,
    flag: '🇬🇳',
  },
  {
    countryCode: 'GW',
    countryNameEn: 'Guinea-Bissau',
    countryNameLocal: 'Guiné-Bissau',
    countryCallingCode: 245,
    flag: '🇬🇼',
  },
  {
    countryCode: 'GY',
    countryNameEn: 'Guyana',
    countryNameLocal: 'Guyana',
    countryCallingCode: 592,
    flag: '🇬🇾',
  },
  {
    countryCode: 'HT',
    countryNameEn: 'Haiti',
    countryNameLocal: 'Haïti, Ayiti',
    countryCallingCode: 509,
    flag: '🇭🇹',
  },
  {
    countryCode: 'VA',
    countryNameEn: 'Holy See',
    countryNameLocal: 'Sancta Sedes',
    countryCallingCode: 39,
    flag: '🇻🇦',
  },
  {
    countryCode: 'HN',
    countryNameEn: 'Honduras',
    countryNameLocal: 'Honduras',
    countryCallingCode: 504,
    flag: '🇭🇳',
  },
  {
    countryCode: 'HK',
    countryNameEn: 'Hong Kong',
    countryNameLocal: '香港, Hong Kong',
    countryCallingCode: 852,
    flag: '🇭🇰',
  },
  {
    countryCode: 'HU',
    countryNameEn: 'Hungary',
    countryNameLocal: 'Magyarország',
    countryCallingCode: 36,
    flag: '🇭🇺',
  },
  {
    countryCode: 'IS',
    countryNameEn: 'Iceland',
    countryNameLocal: 'Ísland',
    countryCallingCode: 354,
    flag: '🇮🇸',
  },
  {
    countryCode: 'IN',
    countryNameEn: 'India',
    countryNameLocal: 'भारत, India',
    countryCallingCode: 91,
    flag: '🇮🇳',
  },
  {
    countryCode: 'ID',
    countryNameEn: 'Indonesia',
    countryNameLocal: 'Indonesia',
    countryCallingCode: 62,
    flag: '🇮🇩',
  },
  {
    countryCode: 'IR',
    countryNameEn: 'Iran (Islamic Republic of)',
    countryNameLocal: 'ایران',
    countryCallingCode: 98,
    flag: '🇮🇷',
  },
  {
    countryCode: 'IQ',
    countryNameEn: 'Iraq',
    countryNameLocal: 'العراق, Iraq',
    countryCallingCode: 964,
    flag: '🇮🇶',
  },
  {
    countryCode: 'IE',
    countryNameEn: 'Ireland',
    countryNameLocal: 'Ireland, Éire',
    countryCallingCode: 353,
    flag: '🇮🇪',
  },
  {
    countryCode: 'IM',
    countryNameEn: 'Isle of Man',
    countryNameLocal: 'Isle of Man',
    countryCallingCode: 44,
    flag: '🇮🇲',
  },
  {
    countryCode: 'IL',
    countryNameEn: 'Israel',
    countryNameLocal: 'ישראל',
    countryCallingCode: 972,
    flag: '🇮🇱',
  },
  {
    countryCode: 'IT',
    countryNameEn: 'Italy',
    countryNameLocal: 'Italia',
    countryCallingCode: 39,
    flag: '🇮🇹',
  },
  {
    countryCode: 'JM',
    countryNameEn: 'Jamaica',
    countryNameLocal: 'Jamaica',
    countryCallingCode: 876,
    flag: '🇯🇲',
  },
  {
    countryCode: 'JP',
    countryNameEn: 'Japan',
    countryNameLocal: '日本',
    countryCallingCode: 81,
    flag: '🇯🇵',
  },
  {
    countryCode: 'JE',
    countryNameEn: 'Jersey',
    countryNameLocal: 'Jersey',
    countryCallingCode: 44,
    flag: '🇯🇪',
  },
  {
    countryCode: 'JO',
    countryNameEn: 'Jordan',
    countryNameLocal: 'الأُرْدُن',
    countryCallingCode: 962,
    flag: '🇯🇴',
  },
  {
    countryCode: 'KZ',
    countryNameEn: 'Kazakhstan',
    countryNameLocal: 'Қазақстан, Казахстан',
    countryCallingCode: 7,
    flag: '🇰🇿',
  },
  {
    countryCode: 'KE',
    countryNameEn: 'Kenya',
    countryNameLocal: 'Kenya',
    countryCallingCode: 254,
    flag: '🇰🇪',
  },
  {
    countryCode: 'KI',
    countryNameEn: 'Kiribati',
    countryNameLocal: 'Kiribati',
    countryCallingCode: 686,
    flag: '🇰🇮',
  },
  {
    countryCode: 'KW',
    countryNameEn: 'Kuwait',
    countryNameLocal: 'الكويت',
    countryCallingCode: 965,
    flag: '🇰🇼',
  },
  {
    countryCode: 'KG',
    countryNameEn: 'Kyrgyzstan',
    countryNameLocal: 'Кыргызстан, Киргизия',
    countryCallingCode: 996,
    flag: '🇰🇬',
  },
  {
    countryCode: 'LA',
    countryNameEn: "Lao People's Democratic Republic",
    countryNameLocal: 'ປະຊາຊົນລາວ',
    countryCallingCode: 856,
    flag: '🇱🇦',
  },
  {
    countryCode: 'LV',
    countryNameEn: 'Latvia',
    countryNameLocal: 'Latvija',
    countryCallingCode: 371,
    flag: '🇱🇻',
  },
  {
    countryCode: 'LB',
    countryNameEn: 'Lebanon',
    countryNameLocal: 'لبنان, Liban',
    countryCallingCode: 961,
    flag: '🇱🇧',
  },
  {
    countryCode: 'LS',
    countryNameEn: 'Lesotho',
    countryNameLocal: 'Lesotho',
    countryCallingCode: 266,
    flag: '🇱🇸',
  },
  {
    countryCode: 'LR',
    countryNameEn: 'Liberia',
    countryNameLocal: 'Liberia',
    countryCallingCode: 231,
    flag: '🇱🇷',
  },
  {
    countryCode: 'LY',
    countryNameEn: 'Libya',
    countryNameLocal: 'ليبيا',
    countryCallingCode: 218,
    flag: '🇱🇾',
  },
  {
    countryCode: 'LI',
    countryNameEn: 'Liechtenstein',
    countryNameLocal: 'Liechtenstein',
    countryCallingCode: 423,
    flag: '🇱🇮',
  },
  {
    countryCode: 'LT',
    countryNameEn: 'Lithuania',
    countryNameLocal: 'Lietuva',
    countryCallingCode: 370,
    flag: '🇱🇹',
  },
  {
    countryCode: 'LU',
    countryNameEn: 'Luxembourg',
    countryNameLocal: 'Lëtzebuerg, Luxembourg, Luxemburg',
    countryCallingCode: 352,
    flag: '🇱🇺',
  },
  {
    countryCode: 'MO',
    countryNameEn: 'Macao',
    countryNameLocal: '澳門, Macau',
    countryCallingCode: 853,
    flag: '🇲🇴',
  },
  {
    countryCode: 'MG',
    countryNameEn: 'Madagascar',
    countryNameLocal: 'Madagasikara, Madagascar',
    countryCallingCode: 261,
    flag: '🇲🇬',
  },
  {
    countryCode: 'MW',
    countryNameEn: 'Malawi',
    countryNameLocal: 'Malawi',
    countryCallingCode: 265,
    flag: '🇲🇼',
  },
  {
    countryCode: 'MY',
    countryNameEn: 'Malaysia',
    countryNameLocal: '',
    countryCallingCode: 60,
    flag: '🇲🇾',
  },
  {
    countryCode: 'MV',
    countryNameEn: 'Maldives',
    countryNameLocal: '',
    countryCallingCode: 960,
    flag: '🇲🇻',
  },
  {
    countryCode: 'ML',
    countryNameEn: 'Mali',
    countryNameLocal: 'Mali',
    countryCallingCode: 223,
    flag: '🇲🇱',
  },
  {
    countryCode: 'MT',
    countryNameEn: 'Malta',
    countryNameLocal: 'Malta',
    countryCallingCode: 356,
    flag: '🇲🇹',
  },
  {
    countryCode: 'MQ',
    countryNameEn: 'Martinique',
    countryNameLocal: 'Martinique',
    countryCallingCode: 596,
    flag: '🇲🇶',
  },
  {
    countryCode: 'MR',
    countryNameEn: 'Mauritania',
    countryNameLocal: 'موريتانيا, Mauritanie',
    countryCallingCode: 222,
    flag: '🇲🇷',
  },
  {
    countryCode: 'MU',
    countryNameEn: 'Mauritius',
    countryNameLocal: 'Maurice, Mauritius',
    countryCallingCode: 230,
    flag: '🇲🇺',
  },
  {
    countryCode: 'YT',
    countryNameEn: 'Mayotte',
    countryNameLocal: 'Mayotte',
    countryCallingCode: 262,
    flag: '🇾🇹',
  },
  {
    countryCode: 'MX',
    countryNameEn: 'Mexico',
    countryNameLocal: 'México',
    countryCallingCode: 52,
    flag: '🇲🇽',
  },
  {
    countryCode: 'FM',
    countryNameEn: 'Micronesia (Federated States of)',
    countryNameLocal: 'Micronesia',
    countryCallingCode: 691,
    flag: '🇫🇲',
  },
  {
    countryCode: 'MC',
    countryNameEn: 'Monaco',
    countryNameLocal: 'Monaco',
    countryCallingCode: 377,
    flag: '🇲🇨',
  },
  {
    countryCode: 'MN',
    countryNameEn: 'Mongolia',
    countryNameLocal: 'Монгол Улс',
    countryCallingCode: 976,
    flag: '🇲🇳',
  },
  {
    countryCode: 'ME',
    countryNameEn: 'Montenegro',
    countryNameLocal: 'Crna Gora, Црна Гора',
    countryCallingCode: 382,
    flag: '🇲🇪',
  },
  {
    countryCode: 'MS',
    countryNameEn: 'Montserrat',
    countryNameLocal: 'Montserrat',
    countryCallingCode: 1664,
    flag: '🇲🇸',
  },
  {
    countryCode: 'MA',
    countryNameEn: 'Morocco',
    countryNameLocal: 'Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب',
    countryCallingCode: 212,
    flag: '🇲🇦',
  },
  {
    countryCode: 'MZ',
    countryNameEn: 'Mozambique',
    countryNameLocal: 'Mozambique',
    countryCallingCode: 258,
    flag: '🇲🇿',
  },
  {
    countryCode: 'MM',
    countryNameEn: 'Myanmar',
    countryNameLocal: 'မြန်မာ',
    countryCallingCode: 95,
    flag: '🇲🇲',
  },
  {
    countryCode: 'NA',
    countryNameEn: 'Namibia',
    countryNameLocal: 'Namibia',
    countryCallingCode: 264,
    flag: '🇳🇦',
  },
  {
    countryCode: 'NR',
    countryNameEn: 'Nauru',
    countryNameLocal: 'Nauru',
    countryCallingCode: 674,
    flag: '🇳🇷',
  },
  {
    countryCode: 'NP',
    countryNameEn: 'Nepal',
    countryNameLocal: '',
    countryCallingCode: 977,
    flag: '🇳🇵',
  },
  {
    countryCode: 'NL',
    countryNameEn: 'Netherlands',
    countryNameLocal: 'Nederland',
    countryCallingCode: 31,
    flag: '🇳🇱',
  },
  {
    countryCode: 'NC',
    countryNameEn: 'New Caledonia',
    countryNameLocal: 'Nouvelle-Calédonie',
    countryCallingCode: 687,
    flag: '🇳🇨',
  },
  {
    countryCode: 'NZ',
    countryNameEn: 'New Zealand',
    countryNameLocal: 'New Zealand',
    countryCallingCode: 64,
    flag: '🇳🇿',
  },
  {
    countryCode: 'NI',
    countryNameEn: 'Nicaragua',
    countryNameLocal: 'Nicaragua',
    countryCallingCode: 505,
    flag: '🇳🇮',
  },
  {
    countryCode: 'NE',
    countryNameEn: 'Niger',
    countryNameLocal: 'Niger',
    countryCallingCode: 227,
    flag: '🇳🇪',
  },
  {
    countryCode: 'NG',
    countryNameEn: 'Nigeria',
    countryNameLocal: 'Nigeria',
    countryCallingCode: 234,
    flag: '🇳🇬',
  },
  {
    countryCode: 'NU',
    countryNameEn: 'Niue',
    countryNameLocal: 'Niue',
    countryCallingCode: 683,
    flag: '🇳🇺',
  },
  {
    countryCode: 'NF',
    countryNameEn: 'Norfolk Island',
    countryNameLocal: 'Norfolk Island',
    countryCallingCode: 672,
    flag: '🇳🇫',
  },
  {
    countryCode: 'KP',
    countryNameEn: 'North Korea',
    countryNameLocal: '조선민주주의인민공화국',
    countryCallingCode: 850,
    flag: '🇰🇵',
  },
  {
    countryCode: 'MK',
    countryNameEn: 'North Macedonia',
    countryNameLocal: 'Македонија',
    countryCallingCode: 389,
    flag: '🇲🇰',
  },
  {
    countryCode: 'NO',
    countryNameEn: 'Norway',
    countryNameLocal: 'Norge, Noreg',
    countryCallingCode: 47,
    flag: '🇳🇴',
  },
  {
    countryCode: 'OM',
    countryNameEn: 'Oman',
    countryNameLocal: 'سلطنة عُمان',
    countryCallingCode: 968,
    flag: '🇴🇲',
  },
  {
    countryCode: 'PK',
    countryNameEn: 'Pakistan',
    countryNameLocal: 'پاکستان',
    countryCallingCode: 92,
    flag: '🇵🇰',
  },
  {
    countryCode: 'PW',
    countryNameEn: 'Palau',
    countryNameLocal: 'Palau',
    countryCallingCode: 680,
    flag: '🇵🇼',
  },
  {
    countryCode: 'PS',
    countryNameEn: 'Palestine, State of',
    countryNameLocal: 'Palestinian Territory',
    countryCallingCode: 970,
    flag: '🇵🇸',
  },
  {
    countryCode: 'PA',
    countryNameEn: 'Panama',
    countryNameLocal: 'Panama',
    countryCallingCode: 507,
    flag: '🇵🇦',
  },
  {
    countryCode: 'PG',
    countryNameEn: 'Papua New Guinea',
    countryNameLocal: 'Papua New Guinea',
    countryCallingCode: 675,
    flag: '🇵🇬',
  },
  {
    countryCode: 'PY',
    countryNameEn: 'Paraguay',
    countryNameLocal: 'Paraguay',
    countryCallingCode: 595,
    flag: '🇵🇾',
  },
  {
    countryCode: 'PE',
    countryNameEn: 'Peru',
    countryNameLocal: 'Perú',
    countryCallingCode: 51,
    flag: '🇵🇪',
  },
  {
    countryCode: 'PH',
    countryNameEn: 'Philippines',
    countryNameLocal: 'Philippines',
    countryCallingCode: 63,
    flag: '🇵🇭',
  },
  {
    countryCode: 'PN',
    countryNameEn: 'Pitcairn',
    countryNameLocal: 'Pitcairn',
    countryCallingCode: 64,
    flag: '🇵🇳',
  },
  {
    countryCode: 'PL',
    countryNameEn: 'Poland',
    countryNameLocal: 'Polska',
    countryCallingCode: 48,
    flag: '🇵🇱',
  },
  {
    countryCode: 'PT',
    countryNameEn: 'Portugal',
    countryNameLocal: 'Portugal',
    countryCallingCode: 351,
    flag: '🇵🇹',
  },
  {
    countryCode: 'PR',
    countryNameEn: 'Puerto Rico',
    countryNameLocal: 'Puerto Rico',
    countryCallingCode: 1,
    flag: '🇵🇷',
  },
  {
    countryCode: 'QA',
    countryNameEn: 'Qatar',
    countryNameLocal: 'قطر',
    countryCallingCode: 974,
    flag: '🇶🇦',
  },
  {
    countryCode: 'MD',
    countryNameEn: 'Republic of Moldova',
    countryNameLocal: 'Moldova, Молдавия',
    countryCallingCode: 373,
    flag: '🇲🇩',
  },
  {
    countryCode: 'CG',
    countryNameEn: 'Republic of the Congo',
    countryNameLocal: 'République du Congo',
    countryCallingCode: 242,
    flag: '🇨🇬',
  },
  {
    countryCode: 'MH',
    countryNameEn: 'Republic of the Marshall Islands',
    countryNameLocal: 'Aolepān Aorōkin Ṃajeḷ',
    countryCallingCode: 692,
    flag: '🇲🇭',
  },
  {
    countryCode: 'RO',
    countryNameEn: 'Romania',
    countryNameLocal: 'România',
    countryCallingCode: 40,
    flag: '🇷🇴',
  },
  {
    countryCode: 'RU',
    countryNameEn: 'Russia',
    countryNameLocal: 'Россия',
    countryCallingCode: 7,
    flag: '🇷🇺',
  },
  {
    countryCode: 'RW',
    countryNameEn: 'Rwanda',
    countryNameLocal: 'Rwanda',
    countryCallingCode: 250,
    flag: '🇷🇼',
  },
  {
    countryCode: 'RE',
    countryNameEn: 'Réunion',
    countryNameLocal: 'La Réunion',
    countryCallingCode: 262,
    flag: '🇷🇪',
  },
  {
    countryCode: 'BL',
    countryNameEn: 'Saint Barthélemy',
    countryNameLocal: 'Saint-Barthélemy',
    countryCallingCode: 590,
    flag: '🇧🇱',
  },
  {
    countryCode: 'SH',
    countryNameEn: 'Saint Helena, Ascension and Tristan da Cunha',
    countryNameLocal: 'Saint Helena',
    countryCallingCode: 290,
    flag: '🇸🇭',
  },
  {
    countryCode: 'KN',
    countryNameEn: 'Saint Kitts and Nevis',
    countryNameLocal: 'Saint Kitts and Nevis',
    countryCallingCode: 1869,
    flag: '🇰🇳',
  },
  {
    countryCode: 'LC',
    countryNameEn: 'Saint Lucia',
    countryNameLocal: 'Saint Lucia',
    countryCallingCode: 1758,
    flag: '🇱🇨',
  },
  {
    countryCode: 'MF',
    countryNameEn: 'Saint Martin (French part)',
    countryNameLocal: 'Saint-Martin',
    countryCallingCode: 590,
    flag: '🇲🇫',
  },
  {
    countryCode: 'PM',
    countryNameEn: 'Saint Pierre and Miquelon',
    countryNameLocal: 'Saint-Pierre-et-Miquelon',
    countryCallingCode: 508,
    flag: '🇵🇲',
  },
  {
    countryCode: 'VC',
    countryNameEn: 'Saint Vincent and the Grenadines',
    countryNameLocal: 'Saint Vincent and the Grenadines',
    countryCallingCode: 1784,
    flag: '🇻🇨',
  },
  {
    countryCode: 'WS',
    countryNameEn: 'Samoa',
    countryNameLocal: 'Samoa',
    countryCallingCode: 685,
    flag: '🇼🇸',
  },
  {
    countryCode: 'SM',
    countryNameEn: 'San Marino',
    countryNameLocal: 'San Marino',
    countryCallingCode: 378,
    flag: '🇸🇲',
  },
  {
    countryCode: 'ST',
    countryNameEn: 'Sao Tome and Principe',
    countryNameLocal: 'São Tomé e Príncipe',
    countryCallingCode: 239,
    flag: '🇸🇹',
  },
  {
    countryCode: 'SA',
    countryNameEn: 'Saudi Arabia',
    countryNameLocal: 'السعودية',
    countryCallingCode: 966,
    flag: '🇸🇦',
  },
  {
    countryCode: 'SN',
    countryNameEn: 'Senegal',
    countryNameLocal: 'Sénégal',
    countryCallingCode: 221,
    flag: '🇸🇳',
  },
  {
    countryCode: 'RS',
    countryNameEn: 'Serbia',
    countryNameLocal: 'Србија',
    countryCallingCode: 381,
    flag: '🇷🇸',
  },
  {
    countryCode: 'SC',
    countryNameEn: 'Seychelles',
    countryNameLocal: 'Seychelles',
    countryCallingCode: 248,
    flag: '🇸🇨',
  },
  {
    countryCode: 'SL',
    countryNameEn: 'Sierra Leone',
    countryNameLocal: 'Sierra Leone',
    countryCallingCode: 232,
    flag: '🇸🇱',
  },
  {
    countryCode: 'SG',
    countryNameEn: 'Singapore',
    countryNameLocal: 'Singapore',
    countryCallingCode: 65,
    flag: '🇸🇬',
  },
  {
    countryCode: 'SX',
    countryNameEn: 'Sint Maarten (Dutch part)',
    countryNameLocal: 'Sint Maarten',
    countryCallingCode: 1721,
    flag: '🇸🇽',
  },
  {
    countryCode: 'SK',
    countryNameEn: 'Slovakia',
    countryNameLocal: 'Slovensko',
    countryCallingCode: 421,
    flag: '🇸🇰',
  },
  {
    countryCode: 'SI',
    countryNameEn: 'Slovenia',
    countryNameLocal: 'Slovenija',
    countryCallingCode: 386,
    flag: '🇸🇮',
  },
  {
    countryCode: 'SB',
    countryNameEn: 'Solomon Islands',
    countryNameLocal: 'Solomon Islands',
    countryCallingCode: 677,
    flag: '🇸🇧',
  },
  {
    countryCode: 'SO',
    countryNameEn: 'Somalia',
    countryNameLocal: 'Somalia, الصومال',
    countryCallingCode: 252,
    flag: '🇸🇴',
  },
  {
    countryCode: 'ZA',
    countryNameEn: 'South Africa',
    countryNameLocal: 'South Africa',
    countryCallingCode: 27,
    flag: '🇿🇦',
  },
  {
    countryCode: 'GS',
    countryNameEn: 'South Georgia and the South Sandwich Islands',
    countryNameLocal: 'South Georgia and the South Sandwich Islands',
    countryCallingCode: 500,
    flag: '🇬🇸',
  },
  {
    countryCode: 'KR',
    countryNameEn: 'South Korea',
    countryNameLocal: '대한민국',
    countryCallingCode: 82,
    flag: '🇰🇷',
  },
  {
    countryCode: 'SS',
    countryNameEn: 'South Sudan',
    countryNameLocal: 'South Sudan',
    countryCallingCode: 211,
    flag: '🇸🇸',
  },
  {
    countryCode: 'ES',
    countryNameEn: 'Spain',
    countryNameLocal: 'España',
    countryCallingCode: 34,
    flag: '🇪🇸',
  },
  {
    countryCode: 'LK',
    countryNameEn: 'Sri Lanka',
    countryNameLocal: 'ශ්‍රී ලංකා, இலங்கை',
    countryCallingCode: 94,
    flag: '🇱🇰',
  },
  {
    countryCode: 'SD',
    countryNameEn: 'Sudan',
    countryNameLocal: 'السودان',
    countryCallingCode: 249,
    flag: '🇸🇩',
  },
  {
    countryCode: 'SR',
    countryNameEn: 'Suriname',
    countryNameLocal: 'Suriname',
    countryCallingCode: 597,
    flag: '🇸🇷',
  },
  {
    countryCode: 'SJ',
    countryNameEn: 'Svalbard and Jan Mayen',
    countryNameLocal: 'Svalbard and Jan Mayen',
    countryCallingCode: 4779,
    flag: '🇸🇯',
  },
  {
    countryCode: 'SE',
    countryNameEn: 'Sweden',
    countryNameLocal: 'Sverige',
    countryCallingCode: 46,
    flag: '🇸🇪',
  },
  {
    countryCode: 'CH',
    countryNameEn: 'Switzerland',
    countryNameLocal: 'Schweiz, Suisse, Svizzera, Svizra',
    countryCallingCode: 41,
    flag: '🇨🇭',
  },
  {
    countryCode: 'SY',
    countryNameEn: 'Syrian Arab Republic',
    countryNameLocal: 'سوريا, Sūriyya',
    countryCallingCode: 963,
    flag: '🇸🇾',
  },
  {
    countryCode: 'TW',
    countryNameEn: 'Taiwan, Province of China',
    countryNameLocal: 'Taiwan',
    countryCallingCode: 886,
    flag: '🇹🇼',
  },
  {
    countryCode: 'TJ',
    countryNameEn: 'Tajikistan',
    countryNameLocal: ',',
    countryCallingCode: 992,
    flag: '🇹🇯',
  },
  {
    countryCode: 'HM',
    countryNameEn: 'Territory of Heard Island and McDonald Islands',
    countryNameLocal: 'Territory of Heard Island and McDonald Islands',
    countryCallingCode: 672,
    flag: '🇭🇲',
  },
  {
    countryCode: 'TH',
    countryNameEn: 'Thailand',
    countryNameLocal: 'ประเทศไทย',
    countryCallingCode: 66,
    flag: '🇹🇭',
  },
  {
    countryCode: 'TL',
    countryNameEn: 'Timor-Leste',
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    countryCallingCode: 670,
    flag: '🇹🇱',
  },
  {
    countryCode: 'TG',
    countryNameEn: 'Togo',
    countryNameLocal: 'Togo',
    countryCallingCode: 228,
    flag: '🇹🇬',
  },
  {
    countryCode: 'TK',
    countryNameEn: 'Tokelau',
    countryNameLocal: 'Tokelau',
    countryCallingCode: 690,
    flag: '🇹🇰',
  },
  {
    countryCode: 'TO',
    countryNameEn: 'Tonga',
    countryNameLocal: 'Tonga',
    countryCallingCode: 676,
    flag: '🇹🇴',
  },
  {
    countryCode: 'TT',
    countryNameEn: 'Trinidad and Tobago',
    countryNameLocal: 'Trinidad and Tobago',
    countryCallingCode: 868,
    flag: '🇹🇹',
  },
  {
    countryCode: 'TN',
    countryNameEn: 'Tunisia',
    countryNameLocal: 'تونس, Tunisie',
    countryCallingCode: 216,
    flag: '🇹🇳',
  },
  {
    countryCode: 'TR',
    countryNameEn: 'Turkey',
    countryNameLocal: 'Türkiye',
    countryCallingCode: 90,
    flag: '🇹🇷',
  },
  {
    countryCode: 'TM',
    countryNameEn: 'Turkmenistan',
    countryNameLocal: 'Türkmenistan',
    countryCallingCode: 993,
    flag: '🇹🇲',
  },
  {
    countryCode: 'TC',
    countryNameEn: 'Turks and Caicos Islands',
    countryNameLocal: 'Turks and Caicos Islands',
    countryCallingCode: 1649,
    flag: '🇹🇨',
  },
  {
    countryCode: 'TV',
    countryNameEn: 'Tuvalu',
    countryNameLocal: 'Tuvalu',
    countryCallingCode: 688,
    flag: '🇹🇻',
  },
  {
    countryCode: 'UG',
    countryNameEn: 'Uganda',
    countryNameLocal: 'Uganda',
    countryCallingCode: 256,
    flag: '🇺🇬',
  },
  {
    countryCode: 'UA',
    countryNameEn: 'Ukraine',
    countryNameLocal: 'Україна',
    countryCallingCode: 380,
    flag: '🇺🇦',
  },
  {
    countryCode: 'AE',
    countryNameEn: 'United Arab Emirates',
    countryNameLocal: 'دولة الإمارات العربيّة المتّحدة',
    countryCallingCode: 971,
    flag: '🇦🇪',
  },
  {
    countryCode: 'GB',
    countryNameEn: 'United Kingdom',
    countryNameLocal: 'Great Britain',
    countryCallingCode: 44,
    flag: '🇬🇧',
  },
  {
    countryCode: 'TZ',
    countryNameEn: 'United Republic of Tanzania',
    countryNameLocal: 'Tanzania',
    countryCallingCode: 255,
    flag: '🇹🇿',
  },
  {
    countryCode: 'UM',
    countryNameEn: 'United States Minor Outlying Islands',
    countryNameLocal: 'United States Minor Outlying Islands',
    countryCallingCode: 246,
    flag: '🇺🇲',
  },
  {
    countryCode: 'US',
    countryNameEn: 'United States of America',
    countryNameLocal: 'United States of America',
    countryCallingCode: 1,
    flag: '🇺🇸',
  },
  {
    countryCode: 'UY',
    countryNameEn: 'Uruguay',
    countryNameLocal: 'Uruguay',
    countryCallingCode: 598,
    flag: '🇺🇾',
  },
  {
    countryCode: 'UZ',
    countryNameEn: 'Uzbekistan',
    countryNameLocal: '',
    countryCallingCode: 998,
    flag: '🇺🇿',
  },
  {
    countryCode: 'VU',
    countryNameEn: 'Vanuatu',
    countryNameLocal: 'Vanuatu',
    countryCallingCode: 678,
    flag: '🇻🇺',
  },
  {
    countryCode: 'VE',
    countryNameEn: 'Venezuela (Bolivarian Republic of)',
    countryNameLocal: 'Venezuela',
    countryCallingCode: 58,
    flag: '🇻🇪',
  },
  {
    countryCode: 'VN',
    countryNameEn: 'Vietnam',
    countryNameLocal: 'Việt Nam',
    countryCallingCode: 84,
    flag: '🇻🇳',
  },
  {
    countryCode: 'VG',
    countryNameEn: 'Virgin Islands (British)',
    countryNameLocal: 'British Virgin Islands',
    countryCallingCode: 1284,
    flag: '🇻🇬',
  },
  {
    countryCode: 'VI',
    countryNameEn: 'Virgin Islands (U.S.)',
    countryNameLocal: 'United States Virgin Islands',
    countryCallingCode: 1340,
    flag: '🇻🇮',
  },
  {
    countryCode: 'WF',
    countryNameEn: 'Wallis and Futuna',
    countryNameLocal: 'Wallis-et-Futuna',
    countryCallingCode: 681,
    flag: '🇼🇫',
  },
  {
    countryCode: 'EH',
    countryNameEn: 'Western Sahara',
    countryNameLocal: 'Sahara Occidental',
    countryCallingCode: 212,
    flag: '🇪🇭',
  },
  {
    countryCode: 'YE',
    countryNameEn: 'Yemen',
    countryNameLocal: 'اليَمَن',
    countryCallingCode: 967,
    flag: '🇾🇪',
  },
  {
    countryCode: 'ZM',
    countryNameEn: 'Zambia',
    countryNameLocal: 'Zambia',
    countryCallingCode: 260,
    flag: '🇿🇲',
  },
  {
    countryCode: 'ZW',
    countryNameEn: 'Zimbabwe',
    countryNameLocal: 'Zimbabwe',
    countryCallingCode: 263,
    flag: '🇿🇼',
  },
  {
    countryCode: 'AX',
    countryNameEn: 'Åland Islands',
    countryNameLocal: 'Åland',
    countryCallingCode: 358,
    flag: '🇦🇽',
  },
];
