import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingSpinnerComponent } from './loading-spinner.component';
export { LoadingSpinnerComponent };

export const MODULE_IMPORTS = [CommonModule, MatProgressSpinnerModule];

export const MODULE_DECLARATIONS = [LoadingSpinnerComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [LoadingSpinnerComponent],
})
export class GalaxyLoadingSpinnerModule {}
