<ng-container *ngIf="!useGalaxyFormField; else galaxyFormField">
  <mat-form-field
    class="galaxy-form-field galaxy-phone-display-container"
    [ngClass]="{
      'no-hint-message': !formControl.invalid || formControl.pristine,
    }"
    appearance="outline"
  >
    <mat-label>{{ label | translate }}</mat-label>
    <input *ngIf="disableAutoComplete" style="display: none" type="text" name="disable-autocomplete" />
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input
      matInput
      class="galaxy-phone-input"
      [ngClass]="{ 'not-focused': !focused }"
      [id]="id"
      [name]="id"
      [formControl]="rawInputControl"
      placeholder="{{ placeholder ?? '' | translate }}"
      [required]="!!required"
      type="tel"
      (focus)="toggleFocus(true)"
      (blur)="blurInput()"
      [autocomplete]="disableAutoComplete ? 'chrome-off' : 'on'"
    />

    <div class="phone-number-formatted" aria-label="phone-number-formatted" *ngIf="!focused">
      <span>
        {{ nationalPhoneNumber$$ | async }}
      </span>
    </div>

    <mat-error *ngIf="validatorError$ | async as validatorError">
      <ng-container *ngIf="validatorError !== ''">
        {{ validatorError | translate }}
      </ng-container>
    </mat-error>
  </mat-form-field>
</ng-container>
<ng-template #galaxyFormField>
  <glxy-form-field
    class="galaxy-form-field galaxy-phone-display-container"
    [ngClass]="{
      'no-hint-message': !formControl.invalid || formControl.pristine,
    }"
    appearance="outline"
  >
    <glxy-label>{{ label | translate }}</glxy-label>
    <input *ngIf="disableAutoComplete" style="display: none" type="text" name="disable-autocomplete" />
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input
      matInput
      class="galaxy-phone-input"
      [ngClass]="{ 'not-focused': !focused }"
      [id]="id"
      [name]="id"
      [formControl]="rawInputControl"
      placeholder="{{ placeholder ?? '' | translate }}"
      [required]="!!required"
      type="tel"
      (focus)="toggleFocus(true)"
      (blur)="blurInput()"
      [autocomplete]="disableAutoComplete ? 'chrome-off' : 'on'"
    />

    <div class="glxy-phone-number-formatted" aria-label="phone-number-formatted" *ngIf="!focused">
      <span>
        {{ nationalPhoneNumber$$ | async }}
      </span>
    </div>

    <glxy-error *ngIf="validatorError$ | async as validatorError">
      <ng-container *ngIf="validatorError !== ''">
        {{ validatorError | translate }}
      </ng-container>
    </glxy-error>
  </glxy-form-field>
</ng-template>
