import { NgModule } from '@angular/core';
import { AiTextButtonComponent } from './ai-text-button.component';
import { CommonModule } from '@angular/common';
import { GalaxyButtonGroupModule } from '@vendasta/galaxy/button-group';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { GalaxyI18NModule } from '@vendasta/galaxy/i18n';
import { TranslateModule } from '@ngx-translate/core';

export const MODULE_IMPORTS = [
  CommonModule,
  GalaxyButtonGroupModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  GalaxyI18NModule,
  TranslateModule,
];

export const MODULE_DECLARATIONS = [AiTextButtonComponent];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  imports: MODULE_IMPORTS,
  exports: [AiTextButtonComponent],
})
export class GalaxyAITextButtonModule {}
