import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GalaxyCoreInputDirective } from '../core/core-input.directive';
import { GalaxySelectInputInterface, SelectInputOption, SelectInputOptionInterface } from '../input.interface';

/** @deprecated - use Galaxy Field with native select or mat-select */
@Component({
  selector: 'glxy-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./../core/core.material-override.scss', './select-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectInputComponent),
      multi: true,
    },
  ],
})
export class SelectInputComponent extends GalaxyCoreInputDirective<string> implements OnInit {
  @HostBinding('class') class = 'glxy-select-input';

  @Input() hint?: string;
  @Input() options?: SelectInputOption[] = [];
  @Input() config?: GalaxySelectInputInterface;

  selectText = '';

  ngOnInit(): void {
    this.setupControl();
  }

  setupControl(): void {
    super.setupControl();

    // If true, use form control initial value to set selected state
    const useFormControl = !!this.formControl?.value;

    const preselected = this.options?.find((opt: SelectInputOption) => {
      const option = opt as SelectInputOptionInterface;
      if (!('options' in opt)) {
        if (useFormControl) {
          return option.value === this.formControl?.value;
        } else {
          return option.selected;
        }
      }
    }) as SelectInputOptionInterface;

    if (preselected) {
      const { value, label } = preselected;
      if (!useFormControl) {
        this.formControl?.setValue(value, { emitEvent: false });
      }

      this.updateSelection(value, label);
    }
  }

  selected(event: any, label: string): void {
    if (event.isUserInput) {
      this.selectText = label;
    }
  }

  updateSelection(value: any, text: string): void {
    this.selectText = text;
    // Need to wait for next frame for
    // ng model and change to pick this up
    window.setTimeout(() => {
      this.inputValue = value;
    });
  }
}
