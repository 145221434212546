<mat-form-field
  class="galaxy-form-field"
  [ngClass]="{
    'no-hint-message': !hint && (!formControl.invalid || formControl.pristine),
  }"
  appearance="outline"
>
  @if (!!label) {
    <mat-label>{{ label | translate }}</mat-label>
  }
  @if (disableAutoComplete) {
    <input style="display: none" type="text" name="disable-autocomplete" />
  }
  <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
  <input
    matInput
    [id]="id"
    [name]="id"
    [formControl]="formControl"
    placeholder="{{ placeholder ? (placeholder | translate) : '' }}"
    [required]="!!required"
    [type]="type"
    (change)="writeValue($event.target.value)"
    [autocomplete]="disableAutoComplete ? 'off' : 'on'"
    (blur)="onBlur()"
  />
  @if (!!trailingIcon) {
    <mat-icon matSuffix [ngClass]="{ 'glxy-icon-clickable': !!iconClickable }" (click)="emitIconClick($event)">
      {{ trailingIcon }}
    </mat-icon>
  }
  @if (hint) {
    <mat-hint class="galaxy-hint">{{ hint | translate }}</mat-hint>
  }

  @if (validatorError$ | async; as validatorError) {
    <mat-error>
      @if (validatorError !== '') {
        {{ validatorError | translate }}
      }
    </mat-error>
  }
</mat-form-field>
